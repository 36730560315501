import { faLocationArrow } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, Icon, Text, Title, desktopFirstMediaQuery } from '@inflearn/ds-react';
import { motion } from 'framer-motion';
import {
  textTransitionAnimateOptions,
  textTransitionInitialOptions,
  textTransitionTimingFunction
} from '../../../../../styles/transitions';
import { css } from '@emotion/react';
import { styleSection } from '../InteractionSections';

const Map = () => {
  return (
    <Box component="section" css={[styleSection, styleMapSection]}>
      <Box css={styleMapBody}>
        <Box css={{ flexShrink: 0 }}>
          <motion.div
            viewport={{ once: true }}
            transition={{ ...textTransitionTimingFunction }}
            initial={textTransitionInitialOptions}
            whileInView={textTransitionAnimateOptions}>
            <Title order={2} size={60} color="dark.9" mb={8} css={styleMapTitle}>
              8월 15일, <br /> 우리 여기서 만나요
            </Title>
          </motion.div>
          <motion.div
            viewport={{ once: true }}
            transition={{ ...textTransitionTimingFunction, delay: 0.2 }}
            initial={textTransitionInitialOptions}
            whileInView={textTransitionAnimateOptions}>
            <Box
              mb={40}
              css={{
                [desktopFirstMediaQuery.mobile]: {
                  marginBottom: '24px'
                }
              }}>
              <Text color="gray.5" size={24} css={styleMapSubTitle}>
                * 교통이 혼잡할 것으로 예상되니,
              </Text>
              <Text color="gray.5" size={24} css={styleMapSubTitle}>
                대중교통 이용을 권장드려요
              </Text>
            </Box>
          </motion.div>
          <motion.div
            viewport={{ once: true }}
            transition={{ ...textTransitionTimingFunction, delay: 0.4 }}
            initial={textTransitionInitialOptions}
            whileInView={textTransitionAnimateOptions}>
            <Button
              component="a"
              variant="outline"
              color="dark"
              size="xl"
              radius="xl"
              href="https://naver.me/GvkWW8by"
              target="_blank"
              css={{
                ':hover': {
                  backgroundColor: '#25262b',
                  color: 'white'
                }
              }}
              leftIcon={<Icon icon={faLocationArrow} />}>
              길찾기
            </Button>
          </motion.div>
        </Box>
        <motion.div
          viewport={{ once: true }}
          transition={{ ...textTransitionTimingFunction, delay: 0.4 }}
          initial={textTransitionInitialOptions}
          whileInView={textTransitionAnimateOptions}
          css={{ width: '100%', height: '100%', flexShrink: 1 }}>
          <Box id="koex-map" css={styleNaverMapWrapper}></Box>
        </motion.div>
      </Box>
    </Box>
  );
};

const styleMapSubTitle = {
  [desktopFirstMediaQuery.tablet]: {
    fontSize: 24
  },
  [desktopFirstMediaQuery.mobile]: {
    fontSize: 14
  }
};

const styleMapSection = css({
  display: 'flex',
  justifyContent: 'center',
  padding: '200px 32px 100px 32px',
  backgroundColor: 'white',
  [desktopFirstMediaQuery.mobile]: {
    padding: '80px 16px 40px 16px'
  }
});

const styleMapBody = css({
  display: 'flex',
  maxWidth: '1200px',
  width: '100%',
  gap: 80,
  [desktopFirstMediaQuery.tablet]: {
    flexDirection: 'column',
    textAlign: 'center'
  },
  [desktopFirstMediaQuery.mobile]: {
    gap: 40
  }
});

const styleMapTitle = css({
  [desktopFirstMediaQuery.tablet]: {
    fontSize: 54
  },
  [desktopFirstMediaQuery.mobile]: {
    fontSize: 28
  }
});

const styleNaverMapWrapper = css({
  borderRadius: '20px',
  width: '100%',
  aspectRatio: '1.7629310344827587 / 1'
});

export default Map;
